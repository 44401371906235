import AppStore from "./AppStore";
import { buildClassName } from "./util";

// id: "2b7be57c-00cc-4856-9a3f-5aa9843690b6"
// title: "About Us"
// type: :html
// key: "about-us-page"
// parent_id: nil
export function AboutUsPage2b7be57c00cc48569a3f5aa9843690b6(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutUsPage2b7be57c00cc48569a3f5aa9843690b6 page`}>
      {props["header"] || <SecondaryMarketingHeader1193e9c58de3410985f53681bba0366c parentTag="Header45a6d2f985f24e939b957d6956fedc4f" title="About Us" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooter43ac7e10539042ea84b7Bdbb3b56b2c9 parentTag="FooterDe0c2771D16c4280814bE9d0946ba7f0" {...props} />}
    </div>
  );
}

// id: "b6ee58d6-f797-4322-9966-b5dbb023e962"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "20cded99-a4f6-46d4-a799-4db4a3953aa1"
export function LinkedLogoB6ee58d6F79743229966B5dbb023e962(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link className="LinkedLogoB6ee58d6F79743229966B5dbb023e962" parentTag={buildClassName("LinkedLogoB6ee58d6F79743229966B5dbb023e962", parentTag)} label={<DefaultLogoF4cfc0d63e044f11Ac54B26c6ff47d08 parentTag="Label56b3f09d07be41f2Bfb1535bdb8f1e97" alt="logo" />} {...props} />
  );
}

// id: "7927ceba-3a80-44a2-bb36-4cd6de2792a1"
// title: "Marketing Primary Navigation"
// type: :text
// key: "navMenuSlug"
// parent_id: "043d1b1b-6be9-42cf-a471-8d672aa977fc"
export const NavMenuSlug7927ceba3a8044a2Bb364cd6de2792a1 = "marketing-primary-nav";

// id: "f6ba1313-561f-421f-af71-44e57849726a"
// title: ""
// type: :gf_preset
// key: "links"
// parent_id: "43ac7e10-5390-42ea-84b7-bdbb3b56b2c9"
export function LinksF6ba1313561f421fAf7144e57849726a(props: any) {
  const Navigation = AppStore.presets["Navigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Navigation className="LinksF6ba1313561f421fAf7144e57849726a" parentTag={buildClassName("LinksF6ba1313561f421fAf7144e57849726a", parentTag)} navMenuSlug="footer-nav" {...props} />
  );
}

// id: "d3f1a0e9-50ed-4e55-86d4-74f45d11b56a"
// title: ""
// type: :text
// key: "class"
// parent_id: "115381b0-8c75-4ee8-bcdf-f9a40f85cef0"
export const ClassD3f1a0e950ed4e5586d474f45d11b56a = "linked-logo";

// id: "9cffc1b9-30e5-453b-8fab-d97c08f5f3ee"
// title: "Join Us Page"
// type: :reference
// key: "join-us-page"
// parent_id: nil
export function JoinUsPage9cffc1b930e5453b8fabD97c08f5f3ee(props: any) {
  return (
    <SecondaryPageD5b6fb8c5a4a41c4Af11B03dfe9f646c parentTag="JoinUsPage9cffc1b930e5453b8fabD97c08f5f3ee" header={<SecondaryMarketingHeader1193e9c58de3410985f53681bba0366c parentTag="HeaderB6624dd9F92c40b9968768a01fe3751d" title="Join Us" />} {...props} />
  );
}

// id: "da2de084-1059-4832-8783-067b42c7adaa"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "1193e9c5-8de3-4109-85f5-3681bba0366c"
export function HeaderTopDa2de084105948328783067b42c7adaa(props: any) {
  return (
    <DefaultHeaderTop26efbdb5868a45c8B275Df0e7cd509a6 parentTag="HeaderTopDa2de084105948328783067b42c7adaa" {...props} />
  );
}

// id: "0972451b-d695-4608-95b3-16d4c38b4ad6"
// title: ""
// type: :reference
// key: "header"
// parent_id: "8b7ed0b3-17e8-4fb4-9eeb-df53a9535651"
export function Header0972451bD695460895b316d4c38b4ad6(props: any) {
  return (
    <SecondaryMarketingHeader1193e9c58de3410985f53681bba0366c parentTag="Header0972451bD695460895b316d4c38b4ad6" title="Public Events" {...props} />
  );
}

// id: "4c6e1689-c05a-44b3-89eb-6c9a7f8d08ca"
// title: ""
// type: :reference
// key: "why-omen"
// parent_id: "632633af-7116-4f91-8c7f-51286c368a1a"
export function WhyOmen4c6e1689C05a44b389eb6c9a7f8d08ca(props: any) {
  return (
    <WhyOmen719677c70c7f4630Ae8214316cf43d55 parentTag="WhyOmen4c6e1689C05a44b389eb6c9a7f8d08ca" {...props} />
  );
}

// id: "4391d9f8-74a4-4f8e-befd-28970696dca5"
// title: "join-omen"
// type: :html
// key: "join-omen"
// parent_id: "f9d69ca0-a054-4319-be62-20a72580e28f"
export function JoinOmen4391d9f874a44f8eBefd28970696dca5(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <section className={`${parentTag || ""} JoinOmen4391d9f874a44f8eBefd28970696dca5`}>
      <div className="omen-container-main">
        <h1 className="omen-container-heading">
          Why Join OMEN?
        </h1>
        <div className="omen-grid-container">
          <div className="omen-grid-item">
            <div>
              <img src="https://mp1md-pub.s3-us-west-2.amazonaws.com/content/1OEb72KdzLf1GZsp_file.svg" alt="Icon 1" />
            </div>
            <div>
              <h3>
                Private Group Of Oregon Men Enjoying Naturism
              </h3>
              <p>
                Connect with other friendly naked men via our various organized activities and form great camaraderie.
              </p>
            </div>
          </div>
          <div className="omen-grid-item">
            <div>
              <img src="https://mp1md-pub.s3-us-west-2.amazonaws.com/content/ygyvuTtS_-VOoNM2_file.svg" alt="Icon 2" />
            </div>
            <div>
              <h3>
                Inclusivity
              </h3>
              <p>
                Open to age-appropriate gay/bi, and trans men of all races, colors, religions, physical disabilities, and ethnic or national origins.
              </p>
            </div>
          </div>
          <div className="omen-grid-item">
            <div>
              <img src="https://mp1md-pub.s3-us-west-2.amazonaws.com/content/xWQBrnOuGSsf8rnm_file.svg" alt="Icon 3" />
            </div>
            <div>
              <h3>
                Monthly Activities And Events
              </h3>
              <p>
                Each month we offer social potlucks, dances, karaoke nights, pool parties, spa nights, camping weekends, themed events, and much more.
              </p>
            </div>
          </div>
          <div className="omen-grid-item">
            <div>
              <img src="https://mp1md-pub.s3-us-west-2.amazonaws.com/content/8FANX31Ya6YXkRRv_file.svg" alt="Icon 4" />
            </div>
            <div>
              <h3>
                No Shaming Policy
              </h3>
              <p>
                We&#39;re regular men, not models and whatever your issue may be, someone else will be larger, smaller, balder, so just be yourself and join us!
              </p>
            </div>
          </div>
          <div className="omen-grid-item">
            <div>
              <img src="https://mp1md-pub.s3-us-west-2.amazonaws.com/content/L5Igdk4e3i6zUwUg_file.svg" alt="Icon 5" />
            </div>
            <div>
              <h3>
                Exclusive Discounts
              </h3>
              <p>
                Save money on spa nights, food, and beverages from our recommended partners and get access to exclusive offers.
              </p>
            </div>
          </div>
          <div className="omen-grid-item">
            <div>
              <img src="https://mp1md-pub.s3-us-west-2.amazonaws.com/content/lW595gIuA-dC63tv_file.svg" alt="Icon 6" />
            </div>
            <div>
              <h3>
                Member Driven Organization
              </h3>
              <p>
                We are not a business venture. Most of our gatherings are in private residences and camping on private, member-owned land.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

// id: "8b7ed0b3-17e8-4fb4-9eeb-df53a9535651"
// title: "Public Events Page"
// type: :html
// key: "public-events-page"
// parent_id: nil
export function PublicEventsPage8b7ed0b317e84fb49eebDf53a9535651(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PublicEventsPage8b7ed0b317e84fb49eebDf53a9535651`}>
      {props["header"] || <SecondaryMarketingHeader1193e9c58de3410985f53681bba0366c parentTag="Header0972451bD695460895b316d4c38b4ad6" title="Public Events" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooter43ac7e10539042ea84b7Bdbb3b56b2c9 parentTag="FooterD02add4249b44d918bee7b133cf2aa3a" {...props} />}
    </div>
  );
}

// id: "f4cfc0d6-3e04-4f11-ac54-b26c6ff47d08"
// title: "Logo"
// type: :html
// key: "default-logo"
// parent_id: nil
export function DefaultLogoF4cfc0d63e044f11Ac54B26c6ff47d08(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3-us-west-2.amazonaws.com/content/qD9ySvYVxmpqtVP1_regular.png" className={`${parentTag || ""} DefaultLogoF4cfc0d63e044f11Ac54B26c6ff47d08 logo`} alt="logo" />
  );
}

// id: "5e9fdbbd-cfe1-4f4d-b899-9c8d48f75671"
// title: ""
// type: :gf_preset
// key: "groupflow"
// parent_id: "43ac7e10-5390-42ea-84b7-bdbb3b56b2c9"
export function Groupflow5e9fdbbdCfe14f4dB8999c8d48f75671(props: any) {
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupFlowTag className="Groupflow5e9fdbbdCfe14f4dB8999c8d48f75671" parentTag={buildClassName("Groupflow5e9fdbbdCfe14f4dB8999c8d48f75671", parentTag)} {...props} />
  );
}

// id: "d02add42-49b4-4d91-8bee-7b133cf2aa3a"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "8b7ed0b3-17e8-4fb4-9eeb-df53a9535651"
export function FooterD02add4249b44d918bee7b133cf2aa3a(props: any) {
  return (
    <DefaultFooter43ac7e10539042ea84b7Bdbb3b56b2c9 parentTag="FooterD02add4249b44d918bee7b133cf2aa3a" {...props} />
  );
}

// id: "de9d3c40-a6a5-4751-93c2-ff74674a03d1"
// title: ""
// type: :text
// key: "title"
// parent_id: "453c8814-8512-4802-a730-5953772a5738"
export const TitleDe9d3c40A6a5475193c2Ff74674a03d1 = "Resources";

// id: "6b9d03bf-78c6-43c0-8f00-2fd7f7b8153a"
// title: ""
// type: :text
// key: "title"
// parent_id: "0972451b-d695-4608-95b3-16d4c38b4ad6"
export const Title6b9d03bf78c643c08f002fd7f7b8153a = "Public Events";

// id: "e71459ba-2997-40d1-b162-89e63681155c"
// title: "Title - Leveling"
// type: :text
// key: "title"
// parent_id: nil
export const TitleE71459ba299740d1B16289e63681155c = "Leveling";

// id: "93ffabe3-7409-4b24-88b3-43b6991a404d"
// title: "Home Page"
// type: :html
// key: "home-page"
// parent_id: nil
export function HomePage93ffabe374094b2488b343b6991a404d(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomePage93ffabe374094b2488b343b6991a404d page`}>
      {props["header"] || <HomeHeaderB8ddd4b56add4287A67cD53709633e2e parentTag="Header495c68a8Fd3a4b239c6fC3cb8fd0bad9" {...props} />}
      {props["welcome-back"] || <WelcomeBackPopUp className="WelcomeBackD89caf97A0ec4e00Ba999206b3f10d10" parentTag={buildClassName("WelcomeBackD89caf97A0ec4e00Ba999206b3f10d10", parentTag)} {...props} />}
      {props["body"] || <BodyF9d69ca0A0544319Be6220a72580e28f />}
      {props["footer"] || <DefaultFooter43ac7e10539042ea84b7Bdbb3b56b2c9 parentTag="Footer87acef838f0746ee92a65ed6a5d488b9" {...props} />}
    </div>
  );
}

// id: "d89caf97-a0ec-4e00-ba99-9206b3f10d10"
// title: ""
// type: :gf_preset
// key: "welcome-back"
// parent_id: "93ffabe3-7409-4b24-88b3-43b6991a404d"
export function WelcomeBackD89caf97A0ec4e00Ba999206b3f10d10(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <WelcomeBackPopUp className="WelcomeBackD89caf97A0ec4e00Ba999206b3f10d10" parentTag={buildClassName("WelcomeBackD89caf97A0ec4e00Ba999206b3f10d10", parentTag)} {...props} />
  );
}

// id: "07fae753-e496-4fd7-95f7-3d209525e8b4"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "f5099290-588a-400e-a59d-08dc41911b5c"
export const PostSlug07fae753E4964fd795f73d209525e8b4 = "home-page-posts2";

// id: "6c2d1298-d9cc-4da9-9d26-f0b4bf40beb2"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "b8ddd4b5-6add-4287-a67c-d53709633e2e"
export function HeaderTop6c2d1298D9cc4da99d26F0b4bf40beb2(props: any) {
  return (
    <DefaultHeaderTop26efbdb5868a45c8B275Df0e7cd509a6 parentTag="HeaderTop6c2d1298D9cc4da99d26F0b4bf40beb2" {...props} />
  );
}

// id: "b2e6f420-1219-451b-9e5e-658f49a7374f"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "b0da8624-90f7-44c6-b998-80c38238ffe6"
export const NavMenuSlugB2e6f4201219451b9e5e658f49a7374f = "marketing-primary-nav";

// id: "24f0b777-2219-487a-83a9-b344758f7f16"
// title: "Common Footer"
// type: :reference
// key: "footer"
// parent_id: nil
export function Footer24f0b7772219487a83a9B344758f7f16(props: any) {
  return (
    <DefaultFooter43ac7e10539042ea84b7Bdbb3b56b2c9 parentTag="Footer24f0b7772219487a83a9B344758f7f16" {...props} />
  );
}

// id: "043d1b1b-6be9-42cf-a471-8d672aa977fc"
// title: "Default Header Reference"
// type: :reference
// key: "default-header"
// parent_id: nil
export function DefaultHeader043d1b1b6be942cfA4718d672aa977fc(props: any) {
  return (
    <ShortHeader20cded99A4f646d4A7994db4a3953aa1 parentTag="DefaultHeader043d1b1b6be942cfA4718d672aa977fc" navMenuSlug="marketing-primary-nav" title {...props} />
  );
}

// id: "20cded99-a4f6-46d4-a799-4db4a3953aa1"
// title: "Short Header"
// type: :html
// key: "short-header"
// parent_id: nil
export function ShortHeader20cded99A4f646d4A7994db4a3953aa1(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} ShortHeader20cded99A4f646d4A7994db4a3953aa1 site-header`}>
      <div className="background-image" />
      <div className="header-top">
        {props["linked-logo"] || <Link className="LinkedLogoB6ee58d6F79743229966B5dbb023e962" parentTag={buildClassName("LinkedLogoB6ee58d6F79743229966B5dbb023e962", parentTag)} label={<DefaultLogoF4cfc0d63e044f11Ac54B26c6ff47d08 parentTag="Label56b3f09d07be41f2Bfb1535bdb8f1e97" alt="logo" />} {...props} />}
        {props["navigation"] || <PrimaryNavigation className="Navigation3f2e4a0f274a430cB77c1ccde7f2901c" parentTag={buildClassName("Navigation3f2e4a0f274a430cB77c1ccde7f2901c", parentTag)} navMenuSlug="members-primary-nav" {...props} />}
      </div>
    </div>
  );
}

// id: "e9bf732f-f784-4b3c-8183-aa72d1b4c9c7"
// title: ""
// type: :reference
// key: "header"
// parent_id: "e3b86aad-9bef-4d19-b1f9-5ab21db4a58e"
export function HeaderE9bf732fF7844b3c8183Aa72d1b4c9c7(props: any) {
  return (
    <SecondaryMarketingHeader1193e9c58de3410985f53681bba0366c parentTag="HeaderE9bf732fF7844b3c8183Aa72d1b4c9c7" title="Leveling" {...props} />
  );
}

// id: "de0c2771-d16c-4280-814b-e9d0946ba7f0"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "2b7be57c-00cc-4856-9a3f-5aa9843690b6"
export function FooterDe0c2771D16c4280814bE9d0946ba7f0(props: any) {
  return (
    <DefaultFooter43ac7e10539042ea84b7Bdbb3b56b2c9 parentTag="FooterDe0c2771D16c4280814bE9d0946ba7f0" {...props} />
  );
}

// id: "5535eb71-6989-4159-a1bc-447dd2dafa10"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "3f2e4a0f-274a-430c-b77c-1ccde7f2901c"
export const NavMenuSlug5535eb7169894159A1bc447dd2dafa10 = "members-primary-nav";

// id: "9f3eb720-6a64-4ba5-a1e7-23689355fcae"
// title: "Label"
// type: :reference
// key: "label"
// parent_id: nil
export function Label9f3eb7206a644ba5A1e723689355fcae(props: any) {
  return (
    <DefaultLogoF4cfc0d63e044f11Ac54B26c6ff47d08 parentTag="Label9f3eb7206a644ba5A1e723689355fcae" {...props} />
  );
}

// id: "ff5bc213-87c7-429a-a66c-31fdc24ebb20"
// title: "Members Header"
// type: :reference
// key: "header"
// parent_id: nil
export function HeaderFf5bc21387c7429aA66c31fdc24ebb20(props: any) {
  return (
    <MembersAreaHeaderAf8adc4860ff465885066f63822010c4 parentTag="HeaderFf5bc21387c7429aA66c31fdc24ebb20" {...props} />
  );
}

// id: "aeaa0292-ae42-40ab-84c7-2d5143f5ea5c"
// title: ""
// type: :reference
// key: "hero"
// parent_id: "1193e9c5-8de3-4109-85f5-3681bba0366c"
export function HeroAeaa0292Ae4240ab84c72d5143f5ea5c(props: any) {
  return (
    <SecondaryMarketingHero612fd25d416c4140977829992aa1eeb4 parentTag="HeroAeaa0292Ae4240ab84c72d5143f5ea5c" title="Secondary Marketing Title 2" {...props} />
  );
}

// id: "874a9be5-8ac8-4162-a331-c4a7d4a9b3ff"
// title: "Resources Page"
// type: :reference
// key: "resources-page"
// parent_id: nil
export function ResourcesPage874a9be58ac84162A331C4a7d4a9b3ff(props: any) {
  return (
    <SecondaryPageD5b6fb8c5a4a41c4Af11B03dfe9f646c parentTag="ResourcesPage874a9be58ac84162A331C4a7d4a9b3ff" header={<SecondaryMarketingHeader1193e9c58de3410985f53681bba0366c parentTag="Header453c881485124802A7305953772a5738" title="Resources" />} {...props} />
  );
}

// id: "8e32b430-78bd-41c2-b917-04fc91389cea"
// title: ""
// type: :gf_preset
// key: "secondary-nav"
// parent_id: "c9efb07b-1034-4eb1-b725-19811fe96b9a"
export function SecondaryNav8e32b43078bd41c2B91704fc91389cea(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <SecondaryNavigation className="SecondaryNav8e32b43078bd41c2B91704fc91389cea" parentTag={buildClassName("SecondaryNav8e32b43078bd41c2B91704fc91389cea", parentTag)} {...props} />
  );
}

// id: "8edfbac5-0f97-4c21-9baf-74410b800d04"
// title: ""
// type: :reference
// key: "header"
// parent_id: "c9efb07b-1034-4eb1-b725-19811fe96b9a"
export function Header8edfbac50f974c219baf74410b800d04(props: any) {
  return (
    <MembersAreaHeaderAf8adc4860ff465885066f63822010c4 parentTag="Header8edfbac50f974c219baf74410b800d04" {...props} />
  );
}

// id: "b6624dd9-f92c-40b9-9687-68a01fe3751d"
// title: ""
// type: :reference
// key: "header"
// parent_id: "9cffc1b9-30e5-453b-8fab-d97c08f5f3ee"
export function HeaderB6624dd9F92c40b9968768a01fe3751d(props: any) {
  return (
    <SecondaryMarketingHeader1193e9c58de3410985f53681bba0366c parentTag="HeaderB6624dd9F92c40b9968768a01fe3751d" title="Join Us" {...props} />
  );
}

// id: "4c035f2d-3248-4c71-8e8f-cbf7e452ffdb"
// title: ""
// type: :text
// key: "title"
// parent_id: "dbb03242-e080-4bd0-beb6-16f28ca8512c"
export const Title4c035f2d32484c718e8fCbf7e452ffdb = "Secondary Page";

// id: "8e06851b-5d40-4d43-8ab2-f3375bb0502f"
// title: "Page Quote"
// type: :html
// key: "page-quote"
// parent_id: nil
export function PageQuote8e06851b5d404d438ab2F3375bb0502f(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PageQuote8e06851b5d404d438ab2F3375bb0502f`}>
      <div>
      </div>
      <div>
      </div>
    </div>
  );
}

// id: "87acef83-8f07-46ee-92a6-5ed6a5d488b9"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "93ffabe3-7409-4b24-88b3-43b6991a404d"
export function Footer87acef838f0746ee92a65ed6a5d488b9(props: any) {
  return (
    <DefaultFooter43ac7e10539042ea84b7Bdbb3b56b2c9 parentTag="Footer87acef838f0746ee92a65ed6a5d488b9" {...props} />
  );
}

// id: "8707e3b5-cbae-4071-84d6-83655c523783"
// title: ""
// type: :text
// key: "buttonClass"
// parent_id: "f5099290-588a-400e-a59d-08dc41911b5c"
export const ButtonClass8707e3b5Cbae407184d683655c523783 = "inline-block px-12 py-3 hover:brightness-110 transition duration";

// id: "03eb2bdb-85da-46c1-9b70-0e5190964ba1"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "e184f5ee-8684-4663-8b39-5253fceb38a9"
export function Footer03eb2bdb85da46c19b700e5190964ba1(props: any) {
  return (
    <DefaultFooter43ac7e10539042ea84b7Bdbb3b56b2c9 parentTag="Footer03eb2bdb85da46c19b700e5190964ba1" {...props} />
  );
}

// id: "03a9688a-5a6c-4f51-882b-915812b57477"
// title: "our-story"
// type: :html
// key: "our-story"
// parent_id: "f9d69ca0-a054-4319-be62-20a72580e28f"
export function OurStory03a9688a5a6c4f51882b915812b57477(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <section className={`${parentTag || ""} OurStory03a9688a5a6c4f51882b915812b57477`}>
      <div className="our-story-main-container">
        <div className="background-container">
          <div className="content-container">
            <div className="text">
              <h1>
                Our Story
              </h1>
              <p>
                The idea for a men’s naturist group in Portland, Oregon was born in the mind of Jim F. Jim,
                having
                been an avid naturist for many years and a member of GNI (Gay Naturists International).
              </p>
              <a href="/about">
                Learn More &gt;
              </a>
            </div>
            <div className="image">
              <img src="https://mp1md-pub.s3-us-west-2.amazonaws.com/content/VsecOwm1SXEF1_1B_regular.jpeg" alt="Group Photo" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

// id: "c67567df-3050-4aad-97ca-e31a459d586a"
// title: "Admin Pages"
// type: :html
// key: "admin-pages"
// parent_id: nil
export function AdminPagesC67567df30504aad97caE31a459d586a(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AdminPagesC67567df30504aad97caE31a459d586a`}>
      {props.children}
    </div>
  );
}

// id: "fa1bffdf-10d4-4c04-ae22-10145a78aafb"
// title: "Signup Pages"
// type: :reference
// key: "signup-pages"
// parent_id: nil
export function SignupPagesFa1bffdf10d44c04Ae2210145a78aafb(props: any) {
  return (
    <SecondaryPageD5b6fb8c5a4a41c4Af11B03dfe9f646c parentTag="SignupPagesFa1bffdf10d44c04Ae2210145a78aafb" header={<SecondaryMarketingHeader1193e9c58de3410985f53681bba0366c parentTag="Header90842c6527134c4b80918b32d65151b5" title="Join Us" />} {...props} />
  );
}

// id: "495c68a8-fd3a-4b23-9c6f-c3cb8fd0bad9"
// title: ""
// type: :reference
// key: "header"
// parent_id: "93ffabe3-7409-4b24-88b3-43b6991a404d"
export function Header495c68a8Fd3a4b239c6fC3cb8fd0bad9(props: any) {
  return (
    <HomeHeaderB8ddd4b56add4287A67cD53709633e2e parentTag="Header495c68a8Fd3a4b239c6fC3cb8fd0bad9" {...props} />
  );
}

// id: "453c8814-8512-4802-a730-5953772a5738"
// title: ""
// type: :reference
// key: "header"
// parent_id: "874a9be5-8ac8-4162-a331-c4a7d4a9b3ff"
export function Header453c881485124802A7305953772a5738(props: any) {
  return (
    <SecondaryMarketingHeader1193e9c58de3410985f53681bba0366c parentTag="Header453c881485124802A7305953772a5738" title="Resources" {...props} />
  );
}

// id: "b7bc2ef1-df2b-4679-a271-3b5a84808785"
// title: ""
// type: :text
// key: "title"
// parent_id: "aeaa0292-ae42-40ab-84c7-2d5143f5ea5c"
export const TitleB7bc2ef1Df2b4679A2713b5a84808785 = "Secondary Marketing Title 2";

// id: "e184f5ee-8684-4663-8b39-5253fceb38a9"
// title: "Members Area"
// type: :html
// key: "members-area-default"
// parent_id: nil
export function MembersAreaDefaultE184f5ee868446638b395253fceb38a9(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MembersAreaDefaultE184f5ee868446638b395253fceb38a9 page`}>
      {props["header"] || <MembersAreaHeaderAf8adc4860ff465885066f63822010c4 parentTag="Header7d3204acA85a4613B84eAaa26dc106c4" navMenuSlug="members-primary-nav" {...props} />}
      {props["footer"] || <DefaultFooter43ac7e10539042ea84b7Bdbb3b56b2c9 parentTag="Footer03eb2bdb85da46c19b700e5190964ba1" {...props} />}
    </div>
  );
}

// id: "1403c577-257e-4214-aa30-2fb555a7ca9c"
// title: ""
// type: :html
// key: "logo"
// parent_id: "43ac7e10-5390-42ea-84b7-bdbb3b56b2c9"
export function Logo1403c577257e4214Aa302fb555a7ca9c(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3-us-west-2.amazonaws.com/content/qD9ySvYVxmpqtVP1_regular.png" alt="Demo 1 logo" className={`${parentTag || ""} Logo1403c577257e4214Aa302fb555a7ca9c logo`} />
  );
}

// id: "f9d69ca0-a054-4319-be62-20a72580e28f"
// title: ""
// type: :html
// key: "body"
// parent_id: "93ffabe3-7409-4b24-88b3-43b6991a404d"
export function BodyF9d69ca0A0544319Be6220a72580e28f(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <section className={`${parentTag || ""} BodyF9d69ca0A0544319Be6220a72580e28f`}>
      {props["join-omen"] || <JoinOmen4391d9f874a44f8eBefd28970696dca5 />}
      {props["omen-image-gallery"] || <OmenImageGallery86650423089b43fe9d42E85a22d2241c />}
      {props["our-story"] || <OurStory03a9688a5a6c4f51882b915812b57477 />}
      {props["more-questions"] || <MoreQuestions71f042f71be848f187018bcb7e852118 />}
    </section>
  );
}

// id: "3f2e4a0f-274a-430c-b77c-1ccde7f2901c"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "20cded99-a4f6-46d4-a799-4db4a3953aa1"
export function Navigation3f2e4a0f274a430cB77c1ccde7f2901c(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation className="Navigation3f2e4a0f274a430cB77c1ccde7f2901c" parentTag={buildClassName("Navigation3f2e4a0f274a430cB77c1ccde7f2901c", parentTag)} navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "eaa71fc5-75d6-4c85-afe5-a7e7961ea398"
// title: ""
// type: :text
// key: "title"
// parent_id: "90842c65-2713-4c4b-8091-8b32d65151b5"
export const TitleEaa71fc575d64c85Afe5A7e7961ea398 = "Join Us";

// id: "af8adc48-60ff-4658-8506-6f63822010c4"
// title: "Members Area Header"
// type: :reference
// key: "members-area-header"
// parent_id: nil
export function MembersAreaHeaderAf8adc4860ff465885066f63822010c4(props: any) {
  return (
    <ShortHeader20cded99A4f646d4A7994db4a3953aa1 parentTag="MembersAreaHeaderAf8adc4860ff465885066f63822010c4" header-top={<DefaultHeaderTop26efbdb5868a45c8B275Df0e7cd509a6 parentTag="HeaderTop7c4bde58226c4c1dA9cc1380b509348e" navMenuSlug="members-primary-nav" />} {...props} />
  );
}

// id: "d5b6fb8c-5a4a-41c4-af11-b03dfe9f646c"
// title: "Secondary Page"
// type: :html
// key: "secondary-page"
// parent_id: nil
export function SecondaryPageD5b6fb8c5a4a41c4Af11B03dfe9f646c(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryPageD5b6fb8c5a4a41c4Af11B03dfe9f646c`}>
      {props["header"] || <SecondaryMarketingHeader1193e9c58de3410985f53681bba0366c parentTag="HeaderDbb03242E0804bd0Beb616f28ca8512c" title="Secondary Page" {...props} />}
      <div className="text-content">
        {props.children}
      </div>
      {props["footer"] || <DefaultFooter43ac7e10539042ea84b7Bdbb3b56b2c9 parentTag="Footer07c8a5ec884345abAe6d0c50860c5e4f" {...props} />}
    </div>
  );
}

// id: "45a6d2f9-85f2-4e93-9b95-7d6956fedc4f"
// title: ""
// type: :reference
// key: "header"
// parent_id: "2b7be57c-00cc-4856-9a3f-5aa9843690b6"
export function Header45a6d2f985f24e939b957d6956fedc4f(props: any) {
  return (
    <SecondaryMarketingHeader1193e9c58de3410985f53681bba0366c parentTag="Header45a6d2f985f24e939b957d6956fedc4f" title="About Us" {...props} />
  );
}

// id: "c9efb07b-1034-4eb1-b725-19811fe96b9a"
// title: "Member Pages"
// type: :html
// key: "member-pages"
// parent_id: nil
export function MemberPagesC9efb07b10344eb1B72519811fe96b9a(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MemberPagesC9efb07b10344eb1B72519811fe96b9a`}>
      {props["header"] || <MembersAreaHeaderAf8adc4860ff465885066f63822010c4 parentTag="Header8edfbac50f974c219baf74410b800d04" {...props} />}
      {props["secondary-nav"] || <SecondaryNavigation className="SecondaryNav8e32b43078bd41c2B91704fc91389cea" parentTag={buildClassName("SecondaryNav8e32b43078bd41c2B91704fc91389cea", parentTag)} {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooter43ac7e10539042ea84b7Bdbb3b56b2c9 parentTag="Footer9d99f36d3c81459d8893Dd200dd54ff3" {...props} />}
    </div>
  );
}

// id: "1193e9c5-8de3-4109-85f5-3681bba0366c"
// title: "Secondary Marketing Header"
// type: :html
// key: "secondary-marketing-header"
// parent_id: nil
export function SecondaryMarketingHeader1193e9c58de3410985f53681bba0366c(props: any) {
  const { parentTag, ...rest } = props;
  props = { title: "Secondary Marketing Title 1", ...rest };
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHeader1193e9c58de3410985f53681bba0366c site-header`}>
      <div className="background-image" />
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTop26efbdb5868a45c8B275Df0e7cd509a6 parentTag="HeaderTopDa2de084105948328783067b42c7adaa" {...props} />}
      </div>
      {props["hero"] || <SecondaryMarketingHero612fd25d416c4140977829992aa1eeb4 parentTag="HeroAeaa0292Ae4240ab84c72d5143f5ea5c" title="Secondary Marketing Title 2" {...props} />}
    </div>
  );
}

// id: "fadaf228-77cf-4c1d-9b7b-5a48f5e53e77"
// title: "Message Pages"
// type: :html
// key: "message-pages"
// parent_id: nil
export function MessagePagesFadaf22877cf4c1d9b7b5a48f5e53e77(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MessagePagesFadaf22877cf4c1d9b7b5a48f5e53e77`}>
      {props["header"] || <MembersAreaHeaderAf8adc4860ff465885066f63822010c4 parentTag="Header39da4f753b7d43e98c7c4f7ad6e8c958" {...props} />}
      {props.children}
    </div>
  );
}

// id: "39da4f75-3b7d-43e9-8c7c-4f7ad6e8c958"
// title: ""
// type: :reference
// key: "header"
// parent_id: "fadaf228-77cf-4c1d-9b7b-5a48f5e53e77"
export function Header39da4f753b7d43e98c7c4f7ad6e8c958(props: any) {
  return (
    <MembersAreaHeaderAf8adc4860ff465885066f63822010c4 parentTag="Header39da4f753b7d43e98c7c4f7ad6e8c958" {...props} />
  );
}

// id: "ea5007d9-d144-49e2-ba9e-46f94edd39b2"
// title: ""
// type: :text
// key: "alt"
// parent_id: "f4cfc0d6-3e04-4f11-ac54-b26c6ff47d08"
export const AltEa5007d9D14449e2Ba9e46f94edd39b2 = "logo";

// id: "86650423-089b-43fe-9d42-e85a22d2241c"
// title: "omen-image-gallery"
// type: :html
// key: "omen-image-gallery"
// parent_id: "f9d69ca0-a054-4319-be62-20a72580e28f"
export function OmenImageGallery86650423089b43fe9d42E85a22d2241c(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} OmenImageGallery86650423089b43fe9d42E85a22d2241c omen-image-main-container`}>
      <div className="omen-image-inner-container">
        <div>
          <h1>
            Images From Past Events
          </h1>
        </div>
        <div className="omen-gallery">
          <div className="omen-gallery-fw-img">
            <img src="https://mp1md-pub.s3-us-west-2.amazonaws.com/content/m3EBzoFl-lnsTJiv_regular.jpeg" alt="Image 1" />
          </div>
          <div className="inner-gallery-container">
            <div className="inner-gallery-left-container">
              <img src="https://mp1md-pub.s3-us-west-2.amazonaws.com/content/QY4rSV-Bl1yr9eWn_regular.jpeg" alt="Image 2" />
              <img src="https://mp1md-pub.s3-us-west-2.amazonaws.com/content/V6el6MEvsG8P210p_regular.jpeg" alt="Image 3" />
              <img src="https://mp1md-pub.s3-us-west-2.amazonaws.com/content/t648HTYQLalFs0uA_regular.jpeg" alt="Image 4" />
            </div>
            <div className="inner-gallery-right-container">
              <img src="https://mp1md-pub.s3-us-west-2.amazonaws.com/content/FiJKcorx594UcY9j_regular.jpeg" alt="Image 5" />
              <img src="https://mp1md-pub.s3-us-west-2.amazonaws.com/content/ipHpKSVapJPjM3fV_regular.jpeg" alt="Image 6" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// id: "a18c64e2-2169-4e95-8ba7-e9fff810330d"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "26363cf6-d5d1-4bb8-a6b5-5cacedf7851d"
export const PostSlugA18c64e221694e958ba7E9fff810330d = "about-us-posts";

// id: "fdd4443e-3cd0-4a1c-97fd-0b41abfa22e6"
// title: "HREF"
// type: :text
// key: "href"
// parent_id: nil
export const HrefFdd4443e3cd04a1c97fd0b41abfa22e6 = "/";

// id: "43ac7e10-5390-42ea-84b7-bdbb3b56b2c9"
// title: "Footer"
// type: :html
// key: "default-footer"
// parent_id: nil
export function DefaultFooter43ac7e10539042ea84b7Bdbb3b56b2c9(props: any) {
  const GroupCopyright = AppStore.presets["GroupCopyright"];
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultFooter43ac7e10539042ea84b7Bdbb3b56b2c9 footer`}>
      <div className="logo-wrapper">
        {props["logo"] || <Logo1403c577257e4214Aa302fb555a7ca9c />}
      </div>
      {props["copyright"] || <GroupCopyright className="CopyrightF491bfe7B3ab4d67980f43df678296b3" parentTag={buildClassName("CopyrightF491bfe7B3ab4d67980f43df678296b3", parentTag)} {...props} />}
      {props["groupflow"] || <GroupFlowTag className="Groupflow5e9fdbbdCfe14f4dB8999c8d48f75671" parentTag={buildClassName("Groupflow5e9fdbbdCfe14f4dB8999c8d48f75671", parentTag)} {...props} />}
    </div>
  );
}

// id: "dbb03242-e080-4bd0-beb6-16f28ca8512c"
// title: ""
// type: :reference
// key: "header"
// parent_id: "d5b6fb8c-5a4a-41c4-af11-b03dfe9f646c"
export function HeaderDbb03242E0804bd0Beb616f28ca8512c(props: any) {
  return (
    <SecondaryMarketingHeader1193e9c58de3410985f53681bba0366c parentTag="HeaderDbb03242E0804bd0Beb616f28ca8512c" title="Secondary Page" {...props} />
  );
}

// id: "07c8a5ec-8843-45ab-ae6d-0c50860c5e4f"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "d5b6fb8c-5a4a-41c4-af11-b03dfe9f646c"
export function Footer07c8a5ec884345abAe6d0c50860c5e4f(props: any) {
  return (
    <DefaultFooter43ac7e10539042ea84b7Bdbb3b56b2c9 parentTag="Footer07c8a5ec884345abAe6d0c50860c5e4f" {...props} />
  );
}

// id: "14804401-f095-41b1-aac0-090faca77e9a"
// title: ""
// type: :text
// key: "class"
// parent_id: "b0da8624-90f7-44c6-b998-80c38238ffe6"
export const Class14804401F09541b1Aac0090faca77e9a = "navigation";

// id: "f5099290-588a-400e-a59d-08dc41911b5c"
// title: "Marketing Cards"
// type: :gf_preset
// key: "marketing-cards"
// parent_id: nil
export function MarketingCardsF5099290588a400eA59d08dc41911b5c(props: any) {
  const MarketingCards = AppStore.presets["MarketingCards"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <MarketingCards className="MarketingCardsF5099290588a400eA59d08dc41911b5c" parentTag={buildClassName("MarketingCardsF5099290588a400eA59d08dc41911b5c", parentTag)} postSlug="home-page-posts2" buttonClass="inline-block px-12 py-3 hover:brightness-110 transition duration" {...props} />
  );
}

// id: "b0da8624-90f7-44c6-b998-80c38238ffe6"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "26efbdb5-868a-45c8-b275-df0e7cd509a6"
export function NavigationB0da862490f744c6B99880c38238ffe6(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation className="NavigationB0da862490f744c6B99880c38238ffe6" parentTag={buildClassName("NavigationB0da862490f744c6B99880c38238ffe6", parentTag)} navMenuSlug="marketing-primary-nav" className="navigation" {...props} />
  );
}

// id: "d126de8c-5f28-4cb5-ae88-5fa1f29aca68"
// title: ""
// type: :text
// key: "title"
// parent_id: "043d1b1b-6be9-42cf-a471-8d672aa977fc"
export const TitleD126de8c5f284cb5Ae885fa1f29aca68 = null;

// id: "7d3204ac-a85a-4613-b84e-aaa26dc106c4"
// title: ""
// type: :reference
// key: "header"
// parent_id: "e184f5ee-8684-4663-8b39-5253fceb38a9"
export function Header7d3204acA85a4613B84eAaa26dc106c4(props: any) {
  return (
    <MembersAreaHeaderAf8adc4860ff465885066f63822010c4 parentTag="Header7d3204acA85a4613B84eAaa26dc106c4" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "719677c7-0c7f-4630-ae82-14316cf43d55"
// title: "WhyOmen"
// type: :html
// key: "why-omen"
// parent_id: nil
export function WhyOmen719677c70c7f4630Ae8214316cf43d55(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} WhyOmen719677c70c7f4630Ae8214316cf43d55 omen-container`}>
      <h1>
        Why Join OMEN?
      </h1>
      <div className="omen-grid-container">
        <div className="omen-grid-item">
          <img src="file-9zs00Ix3ublJ2GcWgwjepT7w" alt="Icon 1" />
          <h3>
            Private Group Of Oregon Men Enjoying Naturism
          </h3>
          <p>
            Connect with other friendly naked men via our various organized activities and form great
            camaraderie.
          </p>
        </div>
        <div className="omen-grid-item">
          <img src="file-9zs00Ix3ublJ2GcWgwjepT7w" alt="Icon 2" />
          <h3>
            Inclusivity
          </h3>
          <p>
            Open to age-appropriate gay/bi men of all races, colors, religions, physical disabilities, and
            ethnic or national origins.
          </p>
        </div>
        <div className="omen-grid-item">
          <img src="file-9zs00Ix3ublJ2GcWgwjepT7w" alt="Icon 3" />
          <h3>
            Monthly Activities And Events
          </h3>
          <p>
            Each month we offer social potlucks, dances, karaoke nights, pool parties, spa nights, camping
            weekends, themed events, and much more.
          </p>
        </div>
        <div className="omen-grid-item">
          <img src="file-9zs00Ix3ublJ2GcWgwjepT7w" alt="Icon 4" />
          <h3>
            No Shaming Policy
          </h3>
          <p>
            We&#39;re regular men, not models and whatever your issue may be, someone else will be larger,
            smaller, balder, so just be yourself and join us!
          </p>
        </div>
        <div className="omen-grid-item">
          <img src="file-9zs00Ix3ublJ2GcWgwjepT7w" alt="Icon 5" />
          <h3>
            Exclusive Discounts
          </h3>
          <p>
            Save money on spa nights, food, and beverages from our recommended partners and get access to
            exclusive offers.
          </p>
        </div>
        <div className="omen-grid-item">
          <img src="file-9zs00Ix3ublJ2GcWgwjepT7w" alt="Icon 6" />
          <h3>
            Member Driven Organization
          </h3>
          <p>
            We are not a business venture. Most of our gatherings are in private residences and camping on
            private, member-owned land.
          </p>
        </div>
      </div>
    </div>
  );
}

// id: "7c4bde58-226c-4c1d-a9cc-1380b509348e"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "af8adc48-60ff-4658-8506-6f63822010c4"
export function HeaderTop7c4bde58226c4c1dA9cc1380b509348e(props: any) {
  return (
    <DefaultHeaderTop26efbdb5868a45c8B275Df0e7cd509a6 parentTag="HeaderTop7c4bde58226c4c1dA9cc1380b509348e" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "18a53fe2-b6dd-46aa-a46f-fdc169c875e4"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "ba9b7233-9e99-461a-890d-534983b43af9"
export const PostSlug18a53fe2B6dd46aaA46fFdc169c875e4 = "home-hero";

// id: "c5369ae4-e361-4bec-acdc-4fa8a2a761c8"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "7d3204ac-a85a-4613-b84e-aaa26dc106c4"
export const NavMenuSlugC5369ae4E3614becAcdc4fa8a2a761c8 = "members-primary-nav";

// id: "e3b86aad-9bef-4d19-b1f9-5ab21db4a58e"
// title: "Levels 1-4 Explained"
// type: :html
// key: "about-leveling-page"
// parent_id: nil
export function AboutLevelingPageE3b86aad9bef4d19B1f95ab21db4a58e(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutLevelingPageE3b86aad9bef4d19B1f95ab21db4a58e`}>
      {props["header"] || <SecondaryMarketingHeader1193e9c58de3410985f53681bba0366c parentTag="HeaderE9bf732fF7844b3c8183Aa72d1b4c9c7" title="Leveling" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooter43ac7e10539042ea84b7Bdbb3b56b2c9 parentTag="Footer9185a496298e4235836fEd654a563296" {...props} />}
    </div>
  );
}

// id: "90842c65-2713-4c4b-8091-8b32d65151b5"
// title: ""
// type: :reference
// key: "header"
// parent_id: "fa1bffdf-10d4-4c04-ae22-10145a78aafb"
export function Header90842c6527134c4b80918b32d65151b5(props: any) {
  return (
    <SecondaryMarketingHeader1193e9c58de3410985f53681bba0366c parentTag="Header90842c6527134c4b80918b32d65151b5" title="Join Us" {...props} />
  );
}

// id: "ba9b7233-9e99-461a-890d-534983b43af9"
// title: ""
// type: :gf_preset
// key: "hero"
// parent_id: "b8ddd4b5-6add-4287-a67c-d53709633e2e"
export function HeroBa9b72339e99461a890d534983b43af9(props: any) {
  const Hero = AppStore.presets["Hero"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Hero className="HeroBa9b72339e99461a890d534983b43af9" parentTag={buildClassName("HeroBa9b72339e99461a890d534983b43af9", parentTag)} postSlug="home-hero" {...props} />
  );
}

// id: "6baf9285-d142-4b7c-a909-ee451c125e3f"
// title: ""
// type: :text
// key: "title"
// parent_id: "b6624dd9-f92c-40b9-9687-68a01fe3751d"
export const Title6baf9285D1424b7cA909Ee451c125e3f = "Join Us";

// id: "632633af-7116-4f91-8c7f-51286c368a1a"
// title: "Marketing Cards Body"
// type: :html
// key: "marketing-body"
// parent_id: nil
export function MarketingBody632633af71164f918c7f51286c368a1a(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MarketingBody632633af71164f918c7f51286c368a1a page-body`} />
  );
}

// id: "9185a496-298e-4235-836f-ed654a563296"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "e3b86aad-9bef-4d19-b1f9-5ab21db4a58e"
export function Footer9185a496298e4235836fEd654a563296(props: any) {
  return (
    <DefaultFooter43ac7e10539042ea84b7Bdbb3b56b2c9 parentTag="Footer9185a496298e4235836fEd654a563296" {...props} />
  );
}

// id: "26efbdb5-868a-45c8-b275-df0e7cd509a6"
// title: "Header Area Top"
// type: :html
// key: "default-header-top"
// parent_id: nil
export function DefaultHeaderTop26efbdb5868a45c8B275Df0e7cd509a6(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultHeaderTop26efbdb5868a45c8B275Df0e7cd509a6 header-top`}>
      {props["linked-logo"] || <Link className="LinkedLogo115381b08c754ee8BcdfF9a40f85cef0" parentTag={buildClassName("LinkedLogo115381b08c754ee8BcdfF9a40f85cef0", parentTag)} className="linked-logo" url="/" label={<DefaultLogoF4cfc0d63e044f11Ac54B26c6ff47d08 parentTag="Label6477780769d24169989182fd59284339" alt="logo" />} {...props} />}
      {props["navigation"] || <PrimaryNavigation className="NavigationB0da862490f744c6B99880c38238ffe6" parentTag={buildClassName("NavigationB0da862490f744c6B99880c38238ffe6", parentTag)} navMenuSlug="marketing-primary-nav" className="navigation" {...props} />}
    </div>
  );
}

// id: "26363cf6-d5d1-4bb8-a6b5-5cacedf7851d"
// title: ""
// type: :reference
// key: "body"
// parent_id: "2b7be57c-00cc-4856-9a3f-5aa9843690b6"
export function Body26363cf6D5d14bb8A6b55cacedf7851d(props: any) {
  return (
    <MarketingBody632633af71164f918c7f51286c368a1a parentTag="Body26363cf6D5d14bb8A6b55cacedf7851d" postSlug="about-us-posts" {...props} />
  );
}

// id: "d16e6112-2921-4c79-b52b-2ab2c8ebd576"
// title: ""
// type: :gf_preset
// key: "quote"
// parent_id: "93ffabe3-7409-4b24-88b3-43b6991a404d"
export function QuoteD16e611229214c79B52b2ab2c8ebd576(props: any) {
  const PageQuote = AppStore.presets["PageQuote"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PageQuote className="QuoteD16e611229214c79B52b2ab2c8ebd576" parentTag={buildClassName("QuoteD16e611229214c79B52b2ab2c8ebd576", parentTag)} contentSlug="home-page-quote" {...props} />
  );
}

// id: "e8330fe8-e83a-4122-b660-b0c21ffcd043"
// title: ""
// type: :text
// key: "url"
// parent_id: "115381b0-8c75-4ee8-bcdf-f9a40f85cef0"
export const UrlE8330fe8E83a4122B660B0c21ffcd043 = "/";

// id: "b8ddd4b5-6add-4287-a67c-d53709633e2e"
// title: "Home Header"
// type: :html
// key: "home-header"
// parent_id: nil
export function HomeHeaderB8ddd4b56add4287A67cD53709633e2e(props: any) {
  const Hero = AppStore.presets["Hero"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomeHeaderB8ddd4b56add4287A67cD53709633e2e site-header`}>
      <div className="background-image" />
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTop26efbdb5868a45c8B275Df0e7cd509a6 parentTag="HeaderTop6c2d1298D9cc4da99d26F0b4bf40beb2" {...props} />}
      </div>
      <div className="hero-banner-container">
        {props["hero"] || <Hero className="HeroBa9b72339e99461a890d534983b43af9" parentTag={buildClassName("HeroBa9b72339e99461a890d534983b43af9", parentTag)} postSlug="home-hero" {...props} />}
      </div>
    </div>
  );
}

// id: "115381b0-8c75-4ee8-bcdf-f9a40f85cef0"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "26efbdb5-868a-45c8-b275-df0e7cd509a6"
export function LinkedLogo115381b08c754ee8BcdfF9a40f85cef0(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link className="LinkedLogo115381b08c754ee8BcdfF9a40f85cef0" parentTag={buildClassName("LinkedLogo115381b08c754ee8BcdfF9a40f85cef0", parentTag)} className="linked-logo" url="/" label={<DefaultLogoF4cfc0d63e044f11Ac54B26c6ff47d08 parentTag="Label6477780769d24169989182fd59284339" alt="logo" />} {...props} />
  );
}

// id: "c0ea5f30-69be-4db4-ba96-42565e9f57e8"
// title: ""
// type: :text
// key: "imageUrl"
// parent_id: "f4cfc0d6-3e04-4f11-ac54-b26c6ff47d08"
export const ImageUrlC0ea5f3069be4db4Ba9642565e9f57e8 = "https://mp1md-pub.s3-us-west-2.amazonaws.com/content/qD9ySvYVxmpqtVP1_regular.png";

// id: "f243acb9-536c-4445-a0de-0ef910a9ac8d"
// title: ""
// type: :text
// key: "contentSlug"
// parent_id: "d16e6112-2921-4c79-b52b-2ab2c8ebd576"
export const ContentSlugF243acb9536c4445A0de0ef910a9ac8d = "home-page-quote";

// id: "4cbe36e0-2e43-4984-9519-1b6c84d7f846"
// title: ""
// type: :text
// key: "title"
// parent_id: "612fd25d-416c-4140-9778-29992aa1eeb4"
export const Title4cbe36e02e43498495191b6c84d7f846 = "Default Title";

// id: "8b2b1865-e77e-4a04-b0b2-d9c74a7bc782"
// title: ""
// type: :text
// key: "backgroundImage"
// parent_id: "b8ddd4b5-6add-4287-a67c-d53709633e2e"
export const BackgroundImage8b2b1865E77e4a04B0b2D9c74a7bc782 = "https://mp1md-pub.s3.amazonaws.com/orgs/demo-1/Moving_Forest_1050_700.webp";

// id: "64777807-69d2-4169-9891-82fd59284339"
// title: ""
// type: :reference
// key: "label"
// parent_id: "115381b0-8c75-4ee8-bcdf-f9a40f85cef0"
export function Label6477780769d24169989182fd59284339(props: any) {
  return (
    <DefaultLogoF4cfc0d63e044f11Ac54B26c6ff47d08 parentTag="Label6477780769d24169989182fd59284339" {...props} />
  );
}

// id: "9d99f36d-3c81-459d-8893-dd200dd54ff3"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "c9efb07b-1034-4eb1-b725-19811fe96b9a"
export function Footer9d99f36d3c81459d8893Dd200dd54ff3(props: any) {
  return (
    <DefaultFooter43ac7e10539042ea84b7Bdbb3b56b2c9 parentTag="Footer9d99f36d3c81459d8893Dd200dd54ff3" {...props} />
  );
}

// id: "8943eb85-a64f-4f11-b29e-51875cd70149"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "7c4bde58-226c-4c1d-a9cc-1380b509348e"
export const NavMenuSlug8943eb85A64f4f11B29e51875cd70149 = "members-primary-nav";

// id: "f491bfe7-b3ab-4d67-980f-43df678296b3"
// title: ""
// type: :gf_preset
// key: "copyright"
// parent_id: "43ac7e10-5390-42ea-84b7-bdbb3b56b2c9"
export function CopyrightF491bfe7B3ab4d67980f43df678296b3(props: any) {
  const GroupCopyright = AppStore.presets["GroupCopyright"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupCopyright className="CopyrightF491bfe7B3ab4d67980f43df678296b3" parentTag={buildClassName("CopyrightF491bfe7B3ab4d67980f43df678296b3", parentTag)} {...props} />
  );
}

// id: "f2572158-8c86-49bb-bb56-f0a7908dc474"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "f6ba1313-561f-421f-af71-44e57849726a"
export const NavMenuSlugF25721588c8649bbBb56F0a7908dc474 = "footer-nav";

// id: "928bd270-ccb5-4b09-ab80-d67ade01a184"
// title: ""
// type: :text
// key: "title"
// parent_id: "1193e9c5-8de3-4109-85f5-3681bba0366c"
export const Title928bd270Ccb54b09Ab80D67ade01a184 = "Secondary Marketing Title 1";

// id: "d8540f25-772d-41b5-bc1e-8a418455b517"
// title: ""
// type: :text
// key: "title"
// parent_id: "45a6d2f9-85f2-4e93-9b95-7d6956fedc4f"
export const TitleD8540f25772d41b5Bc1e8a418455b517 = "About Us";

// id: "ee4aa7c7-d222-45ee-b8d5-8670ef9713a4"
// title: ""
// type: :text
// key: "title"
// parent_id: "e9bf732f-f784-4b3c-8183-aa72d1b4c9c7"
export const TitleEe4aa7c7D22245eeB8d58670ef9713a4 = "Leveling";

// id: "71f042f7-1be8-48f1-8701-8bcb7e852118"
// title: "more-questions"
// type: :html
// key: "more-questions"
// parent_id: "f9d69ca0-a054-4319-be62-20a72580e28f"
export function MoreQuestions71f042f71be848f187018bcb7e852118(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <section className={`${parentTag || ""} MoreQuestions71f042f71be848f187018bcb7e852118 faq-section`}>
      <div className="faq-main-container">
        <h2>
          FAQs
        </h2>
        <h3>
          Have More Questions?
        </h3>
        <details>
          <summary>
            How Big Is The Community?
          </summary>
          <p>
            The OMEN community has over 260 active members and we are growing each month.
          </p>
        </details>
        <details>
          <summary>
            Where Are The Members Located?
          </summary>
          <p>
            Our members are located in Oregon, Washington and many other states.
          </p>
        </details>
        <details>
          <summary>
            Who Should Not Apply?
          </summary>
          <p>
            All our events are completely naked. We are not a clothing optional group, and if you are uncomfortable with this you should not apply.
          </p>
        </details>
        <details>
          <summary>
            Is There An Age Limit?
          </summary>
          <p>
            Members must be at least 21 years old to join OMEN.
          </p>
        </details>
        <details>
          <summary>
            What Kind Of Discounts Are Available?
          </summary>
          <p>
            We offer a variety of discounts to our members from our partners in the Portland Vancouver metro area.
          </p>
        </details>
        <details>
          <summary>
            Can I Host An Event?
          </summary>
          <p>
            Yes, members can apply and request to host nude events after fulfilling certain criteria.
          </p>
        </details>
        <div className="buttons">
          <a href="/join">
            <button className="member-btn transition" onclick="window.location.href=&#39;#&#39;">
              Become A Member
            </button>
          </a>
          <a href="/events/public">
            <button className="events-btn transition" onclick="window.location.href=&#39;#&#39;">
              View Upcoming Events
            </button>
          </a>
        </div>
      </div>
    </section>
  );
}

// id: "56b3f09d-07be-41f2-bfb1-535bdb8f1e97"
// title: ""
// type: :reference
// key: "label"
// parent_id: "b6ee58d6-f797-4322-9966-b5dbb023e962"
export function Label56b3f09d07be41f2Bfb1535bdb8f1e97(props: any) {
  return (
    <DefaultLogoF4cfc0d63e044f11Ac54B26c6ff47d08 parentTag="Label56b3f09d07be41f2Bfb1535bdb8f1e97" {...props} />
  );
}

// id: "612fd25d-416c-4140-9778-29992aa1eeb4"
// title: "Secondary Marketing Hero"
// type: :html
// key: "secondary-marketing-hero"
// parent_id: nil
export function SecondaryMarketingHero612fd25d416c4140977829992aa1eeb4(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHero612fd25d416c4140977829992aa1eeb4 hero`}>
      <div className="title">
        {props["title"] || Title4cbe36e02e43498495191b6c84d7f846}
      </div>
    </div>
  );
}

const Components = {
  AboutUsPage2b7be57c00cc48569a3f5aa9843690b6,
  LinkedLogoB6ee58d6F79743229966B5dbb023e962,
  NavMenuSlug7927ceba3a8044a2Bb364cd6de2792a1,
  LinksF6ba1313561f421fAf7144e57849726a,
  ClassD3f1a0e950ed4e5586d474f45d11b56a,
  JoinUsPage9cffc1b930e5453b8fabD97c08f5f3ee,
  HeaderTopDa2de084105948328783067b42c7adaa,
  Header0972451bD695460895b316d4c38b4ad6,
  WhyOmen4c6e1689C05a44b389eb6c9a7f8d08ca,
  JoinOmen4391d9f874a44f8eBefd28970696dca5,
  PublicEventsPage8b7ed0b317e84fb49eebDf53a9535651,
  DefaultLogoF4cfc0d63e044f11Ac54B26c6ff47d08,
  Groupflow5e9fdbbdCfe14f4dB8999c8d48f75671,
  FooterD02add4249b44d918bee7b133cf2aa3a,
  TitleDe9d3c40A6a5475193c2Ff74674a03d1,
  Title6b9d03bf78c643c08f002fd7f7b8153a,
  TitleE71459ba299740d1B16289e63681155c,
  HomePage93ffabe374094b2488b343b6991a404d,
  WelcomeBackD89caf97A0ec4e00Ba999206b3f10d10,
  PostSlug07fae753E4964fd795f73d209525e8b4,
  HeaderTop6c2d1298D9cc4da99d26F0b4bf40beb2,
  NavMenuSlugB2e6f4201219451b9e5e658f49a7374f,
  Footer24f0b7772219487a83a9B344758f7f16,
  DefaultHeader043d1b1b6be942cfA4718d672aa977fc,
  ShortHeader20cded99A4f646d4A7994db4a3953aa1,
  HeaderE9bf732fF7844b3c8183Aa72d1b4c9c7,
  FooterDe0c2771D16c4280814bE9d0946ba7f0,
  NavMenuSlug5535eb7169894159A1bc447dd2dafa10,
  Label9f3eb7206a644ba5A1e723689355fcae,
  HeaderFf5bc21387c7429aA66c31fdc24ebb20,
  HeroAeaa0292Ae4240ab84c72d5143f5ea5c,
  ResourcesPage874a9be58ac84162A331C4a7d4a9b3ff,
  SecondaryNav8e32b43078bd41c2B91704fc91389cea,
  Header8edfbac50f974c219baf74410b800d04,
  HeaderB6624dd9F92c40b9968768a01fe3751d,
  Title4c035f2d32484c718e8fCbf7e452ffdb,
  PageQuote8e06851b5d404d438ab2F3375bb0502f,
  Footer87acef838f0746ee92a65ed6a5d488b9,
  ButtonClass8707e3b5Cbae407184d683655c523783,
  Footer03eb2bdb85da46c19b700e5190964ba1,
  OurStory03a9688a5a6c4f51882b915812b57477,
  AdminPagesC67567df30504aad97caE31a459d586a,
  SignupPagesFa1bffdf10d44c04Ae2210145a78aafb,
  Header495c68a8Fd3a4b239c6fC3cb8fd0bad9,
  Header453c881485124802A7305953772a5738,
  TitleB7bc2ef1Df2b4679A2713b5a84808785,
  MembersAreaDefaultE184f5ee868446638b395253fceb38a9,
  Logo1403c577257e4214Aa302fb555a7ca9c,
  BodyF9d69ca0A0544319Be6220a72580e28f,
  Navigation3f2e4a0f274a430cB77c1ccde7f2901c,
  TitleEaa71fc575d64c85Afe5A7e7961ea398,
  MembersAreaHeaderAf8adc4860ff465885066f63822010c4,
  SecondaryPageD5b6fb8c5a4a41c4Af11B03dfe9f646c,
  Header45a6d2f985f24e939b957d6956fedc4f,
  MemberPagesC9efb07b10344eb1B72519811fe96b9a,
  SecondaryMarketingHeader1193e9c58de3410985f53681bba0366c,
  MessagePagesFadaf22877cf4c1d9b7b5a48f5e53e77,
  Header39da4f753b7d43e98c7c4f7ad6e8c958,
  AltEa5007d9D14449e2Ba9e46f94edd39b2,
  OmenImageGallery86650423089b43fe9d42E85a22d2241c,
  PostSlugA18c64e221694e958ba7E9fff810330d,
  HrefFdd4443e3cd04a1c97fd0b41abfa22e6,
  DefaultFooter43ac7e10539042ea84b7Bdbb3b56b2c9,
  HeaderDbb03242E0804bd0Beb616f28ca8512c,
  Footer07c8a5ec884345abAe6d0c50860c5e4f,
  Class14804401F09541b1Aac0090faca77e9a,
  MarketingCardsF5099290588a400eA59d08dc41911b5c,
  NavigationB0da862490f744c6B99880c38238ffe6,
  TitleD126de8c5f284cb5Ae885fa1f29aca68,
  Header7d3204acA85a4613B84eAaa26dc106c4,
  WhyOmen719677c70c7f4630Ae8214316cf43d55,
  HeaderTop7c4bde58226c4c1dA9cc1380b509348e,
  PostSlug18a53fe2B6dd46aaA46fFdc169c875e4,
  NavMenuSlugC5369ae4E3614becAcdc4fa8a2a761c8,
  AboutLevelingPageE3b86aad9bef4d19B1f95ab21db4a58e,
  Header90842c6527134c4b80918b32d65151b5,
  HeroBa9b72339e99461a890d534983b43af9,
  Title6baf9285D1424b7cA909Ee451c125e3f,
  MarketingBody632633af71164f918c7f51286c368a1a,
  Footer9185a496298e4235836fEd654a563296,
  DefaultHeaderTop26efbdb5868a45c8B275Df0e7cd509a6,
  Body26363cf6D5d14bb8A6b55cacedf7851d,
  QuoteD16e611229214c79B52b2ab2c8ebd576,
  UrlE8330fe8E83a4122B660B0c21ffcd043,
  HomeHeaderB8ddd4b56add4287A67cD53709633e2e,
  LinkedLogo115381b08c754ee8BcdfF9a40f85cef0,
  ImageUrlC0ea5f3069be4db4Ba9642565e9f57e8,
  ContentSlugF243acb9536c4445A0de0ef910a9ac8d,
  Title4cbe36e02e43498495191b6c84d7f846,
  BackgroundImage8b2b1865E77e4a04B0b2D9c74a7bc782,
  Label6477780769d24169989182fd59284339,
  Footer9d99f36d3c81459d8893Dd200dd54ff3,
  NavMenuSlug8943eb85A64f4f11B29e51875cd70149,
  CopyrightF491bfe7B3ab4d67980f43df678296b3,
  NavMenuSlugF25721588c8649bbBb56F0a7908dc474,
  Title928bd270Ccb54b09Ab80D67ade01a184,
  TitleD8540f25772d41b5Bc1e8a418455b517,
  TitleEe4aa7c7D22245eeB8d58670ef9713a4,
  MoreQuestions71f042f71be848f187018bcb7e852118,
  Label56b3f09d07be41f2Bfb1535bdb8f1e97,
  SecondaryMarketingHero612fd25d416c4140977829992aa1eeb4
}

export default Components;