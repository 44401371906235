const ROUTES = {
  "/": {
    "name": "HomePage93ffabe374094b2488b343b6991a404d",
    "type": "HTML",
    "key": "home-page"
  },
  "/_admin/*": {
    "name": "AdminPagesC67567df30504aad97caE31a459d586a",
    "type": "HTML",
    "key": "admin-pages"
  },
  "/about": {
    "name": "AboutUsPage2b7be57c00cc48569a3f5aa9843690b6",
    "type": "HTML",
    "key": "about-us-page"
  },
  "/about/leveling": {
    "name": "AboutLevelingPageE3b86aad9bef4d19B1f95ab21db4a58e",
    "type": "HTML",
    "key": "about-leveling-page"
  },
  "/events/public": {
    "name": "PublicEventsPage8b7ed0b317e84fb49eebDf53a9535651",
    "type": "HTML",
    "key": "public-events-page"
  },
  "/join": {
    "name": "JoinUsPage9cffc1b930e5453b8fabD97c08f5f3ee",
    "type": "REFERENCE",
    "key": "join-us-page"
  },
  "/members/*": {
    "name": "MemberPagesC9efb07b10344eb1B72519811fe96b9a",
    "type": "HTML",
    "key": "member-pages"
  },
  "/members/messages/*": {
    "name": "MessagePagesFadaf22877cf4c1d9b7b5a48f5e53e77",
    "type": "HTML",
    "key": "message-pages"
  },
  "/resources": {
    "name": "ResourcesPage874a9be58ac84162A331C4a7d4a9b3ff",
    "type": "REFERENCE",
    "key": "resources-page"
  },
  "/signup/*": {
    "name": "SignupPagesFa1bffdf10d44c04Ae2210145a78aafb",
    "type": "REFERENCE",
    "key": "signup-pages"
  }
};

export default ROUTES;